import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Layout from '../layouts'

import { Wrapper } from '../styledComponents'

const ScrollContainer = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-flow: row wrap;
`

const Item = styled.div`
  margin-bottom: 2rem;
  border-radius: 4px;
  position: relative;
  padding: 0.5rem 5rem 0.5rem 1rem;
  background: ${({ isError }) =>
    isError ? 'rgba(180,0,0,.2)' : 'rgba(0,180,0,.2)'};
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  &:nth-child(2n) {
    margin-left: 32px;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
`

const Heading = styled.h4`
  margin: 0 0 0.25rem 0;
  padding: 0;
  font: 1.25rem/1.5;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  flex: 0 0 100%;
`
const Link = styled.a`
  display: block;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
  flex: 0 1 auto;
  background: ${({ error }) =>
    error ? 'rgba(200,0,0,.2)' : 'rgba(0,200,0,.2)'};
  & + & {
    margin-right: 16px;
  }
`

const Status = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 1rem;
  color: ${({ isError }) => (isError ? 'darkred' : 'darkGreen')};
  font-weight: bold;
`
const compare = (a, b) => {
  let sA = a.statuscode < 0 ? 299 : a.statuscode
  let sB = b.statuscode < 0 ? 299 : b.statuscode
  return sA > sB ? -1 : 1
}

const LinkCheck = ({ pageContext }) => {
  const [results, setResults] = useState([])

  useEffect(() => {
    setResults(pageContext.linksContext.sort(compare))
  }, [])

  return (
    <Layout>
      <Wrapper>
        <ScrollContainer>
          {results.map(item => {
            const isError =
              item.statuscode < 0 ||
              (400 <= item.statuscode && item.statuscode < 503)
            return (
              <Item isError={isError} key={item['cf_id']}>
                <Row>
                  <Heading isError={isError}>{item.title}</Heading>
                </Row>
                <Row>
                  {isError ? (
                    <Link
                      error
                      href={`https://app.contentful.com/spaces/axpu5qu9wjnx/entries/${
                        item['cf_id']
                      }`}
                      target="_blank"
                      rel="noopener"
                    >
                      Entry aanpassen
                    </Link>
                  ) : (
                    <Link href={item.url} target="_blank" rel="noopener">
                      Open formulier
                    </Link>
                  )}
                </Row>
                <Status isError={isError}>
                  {item.statuscode < 0 ? 'geen info' : item.statuscode}
                </Status>
              </Item>
            )
          })}
        </ScrollContainer>
      </Wrapper>
    </Layout>
  )
}

export default LinkCheck
